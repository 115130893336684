html {
  color: #222;
  font-family: sans-serif;
  font-size: 1em;
  line-height: 1.4;
}

body {
  flex: 1;
  display: flex;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden, [hidden] {
  display: none !important;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only.focusable:active, .sr-only.focusable:focus {
  clip: auto;
  height: auto;
  white-space: inherit;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

#map {
  height: 100vh;
  flex: 1;
  display: flex;
}

.pwd-by {
  z-index: 2000;
  width: 200px;
  opacity: .8;
  background: #fff;
  border-radius: 10px;
  padding: 5px;
  position: fixed;
  bottom: 540px;
  left: 10px;
}

.pwd-by img {
  max-width: 100%;
}

.charts {
  z-index: 999;
  width: 370px;
  background: #fff;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  position: absolute;
  bottom: 25px;
  left: 10px;
}

.kp-index {
  z-index: 9999;
  position: absolute;
  top: 10px;
  right: 330px;
}

.button {
  height: 30px;
  text-align: center;
  color: #00000080;
  cursor: pointer;
  background: #fff;
  border: 1px solid #0000004d;
  border-radius: 5px;
  padding: 0 10px;
  line-height: 30px;
  transition: all .2s;
  display: inline-block;
}

.button:hover {
  box-shadow: 0 0 9px 4px #0006;
}

.button.level-1, .button.level-2, .button.level-3, .button.level-4, .button.level-5 {
  background: #92d050;
}

.button.level-6 {
  background: #f6eb14;
}

.button.level-7 {
  background: #ffc800;
}

.button.level-8, .button.level-9 {
  background: #ff9600;
}

.button.level-10 {
  background: #c80000;
}

.ng-fa-icon {
  margin-right: 5px;
}

.data-source {
  z-index: 2000;
  width: 200px;
  opacity: .8;
  background: #fff;
  border-radius: 10px;
  padding: 5px;
  position: fixed;
  bottom: 440px;
  left: 10px;
}

.data-source p {
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 0;
  font-size: 11px;
}

.data-source img {
  max-width: 100%;
}

.info {
  z-index: 2007;
  position: fixed;
  top: 10px;
  left: 53px;
}

.info a {
  color: #fff;
  padding: 5px;
  font-size: 20px;
}

.info .img {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
}

.download-button {
  z-index: 1000;
  color: #333;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 7px 10px;
  text-decoration: none;
  position: absolute;
  top: 10px;
  left: 100px;
}

.download-button:hover {
  color: #fff;
  background: #333;
  border-color: #fff;
}

.trisonica {
  z-index: 1200;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  position: fixed;
  top: 160px;
  right: 10px;
}

.trisonica ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.trisonica ul li {
  align-items: center;
  margin: 5px 0;
  display: flex;
}

.trisonica ul li.hovered {
  background: #fff1b0;
}

.trisonica ul li.mid {
  color: #fa9733;
}

.trisonica ul li.strong {
  color: red;
}

.trisonica ul li > span {
  width: 50px;
  justify-content: center;
  margin: 0 10px;
  display: flex;
}

.trisonica ul li div {
  text-align: center;
  width: 40px;
  margin: 0 10px;
}

.trisonica ul li div.location-date {
  width: 170px;
  text-align: right;
  flex-direction: column;
  display: flex;
}

.trisonica ul li div.location-date .location {
  font-weight: bold;
}

.trisonica ul li div.location-date .date {
  font-size: 10px;
}

.trisonica ul li div.wind-speed {
  flex-direction: column;
  font-size: 12px;
  display: flex;
}

.trisonica .gust, .trisonica .average, .trisonica .temperature, .trisonica .wind-dir {
  align-items: center;
  display: flex;
}

.trisonica .wind-dir {
  border-left: 1px solid #aaa;
  align-items: center;
  margin-right: 0;
  display: flex;
}

.trisonica li.strong .wind-dir, .trisonica li.strong .temperature {
  border-color: red;
}

.trisonica .gust:after, .trisonica .average:after {
  content: " km/h";
  margin-left: 3px;
  font-size: 11px;
  display: flex;
}

.trisonica .temperature {
  border-left: 1px solid #aaa;
  padding-left: 5px;
}

.trisonica .temperature:after {
  content: " °C";
  margin-left: 3px;
  font-size: 11px;
  display: flex;
}

.trisonica .wind-dir:after {
  content: " °";
  margin-left: 3px;
  font-size: 11px;
  display: flex;
}

path {
  stroke: none;
  opacity: .4;
}

.tooltip {
  z-index: 666;
  background: #ffffffb3;
  border-radius: 10px;
  padding: 10px;
  position: fixed;
}

.lastupdate {
  z-index: 1000;
  text-align: right;
  width: 290px;
  background: #fff;
  border-radius: 5px 5px 5px 0;
  padding: 5px;
  font-family: sans-serif;
  font-size: 11px;
  position: fixed;
  top: 10px;
  right: 10px;
  overflow: hidden;
}

.warning-forecast {
  z-index: 1000;
  background: #eee;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: fixed;
  top: 97px;
  right: 290px;
}

.warning-forecast .warning-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 9px;
  display: block;
}

.warning-forecast .warning-circle.green {
  background: #32dc18;
}

.warning-forecast .warning-circle.orange {
  background: #dc8118;
}

.warning-forecast .warning-circle.yellow {
  background: #d5dc18;
}

.warning-forecast .warning-circle.red {
  background: #dc1818;
}

.chart {
  z-index: 2000;
  width: 500px;
  padding-bottom: 40px;
  padding-right: 30px;
  transition: all .2s;
  position: fixed;
  bottom: 0;
  right: 0;
}

.chart img {
  width: 500px;
  border-radius: 15px;
  transition: all .2s;
}

.chart.hovered img, .chart.hover-fixed img {
  width: 640px;
  border-radius: 30px;
}

.chart.hover-fixed img {
  box-shadow: 0 0 10px 5px #3964ff;
}

.large-text {
  background: #eee;
  border-top: 1px solid #dedede;
  margin: 5px -5px -5px;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
}

@media only screen and (max-width: 1100px) {
  .chart.hovered img, .chart.hover-fixed img {
    width: 100%;
    border-radius: 30px;
  }
}

.sidebar {
  height: 100vh;
  width: 70px;
  z-index: 500;
  background: #1a2e39;
  border-right: 1px solid #43415e;
  flex-direction: column;
  display: flex;
}

.sidebar ul {
  flex-direction: column;
  flex: 1;
  margin: 0;
  padding: 0;
  display: flex;
}

.sidebar ul li {
  margin: 10px;
  display: flex;
}

.sidebar ul li.active {
  color: #1f0;
  background: #0000001a;
}

.sidebar ul li.active i {
  width: 100%;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  line-height: 38px;
  transition: all .3s;
  display: block;
}

.sidebar ul li a {
  width: 100%;
  color: #fdf9f0;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  line-height: 38px;
  transition: all .3s;
  display: block;
}

.sidebar ul li a:hover {
  background: #32495c;
}

.sidebar ul li a svg {
  fill: #fdf9f0;
  width: 20px;
  margin: 0 auto;
  padding: 10px 0;
  display: block;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

/*# sourceMappingURL=index.8e8622c0.css.map */
